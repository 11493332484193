import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, ModalBody, ModalHeader, Modal } from 'reactstrap';
import { withTheme } from 'react-jsonschema-form';
import LayoutField from 'react-jsonschema-form-layout-2';
import Bootstrap4Theme from 'react-jsonschema-form-bs4';
import Loader from '../../../../components/common/Loader';
import LoadingOverlay from 'react-loading-overlay';
import { markAsCancel } from '../../../../services/ssg/ssg';
import newId from '../../../../helpers/newId';
import uiSchema from './UiSchema';

const fields = {
  layout: LayoutField
};

const overlay = {
  overlay: base => ({
    ...base,
    background: 'rgb(236, 237, 237, 0.3)'
  })
};

const Form = withTheme(Bootstrap4Theme);

/**
 * Cancel popup window
 * @param {*} props
 * @returns
 */
const Cancel = props => {
  const modalVisibale = props.isOpen;

  const token = useSelector(state => state.Ssg.token);

  const [error, seterror] = useState([]);
  const [loading, setloading] = useState(false);
  const [formdata, setformdata] = useState([]);
  const [rfidtag, setrfidtag] = useState(null);
  const [id, setid] = useState(null);
  const [text, settext] = useState(null);

  useEffect(() => {
    if (props.formData !== null) {
      setrfidtag(props.formData.rfid_tag);
      setid(props.formData.id);
      settext(props.formData.text);
    }
  }, [props]);

  /**
   * Call mark as duplicate API endpoint
   * @param {*} values
   */
  const handleSubmit = values => {
    seterror([]);
    setloading(true);
    setformdata(values.formData);

    markAsCancel(id, token)
      .then(response => {
        if (response.status === 200) {
          setformdata([]);
          props.toggle();
        }
      })
      .catch(err => {
        try {
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          seterror(['Please try again later...']);
        }
      });

    setTimeout(function() {
      setloading(false);
    }, 1000);
  };

  /**
   * Handle close pop up window
   * @param {*} e
   */
  const handleClose = e => {
    setformdata([]);
    seterror([]);
    e.preventDefault();
    props.cancel();
  };

  /**
   * Schema for form
   */

  let schema = {
    properties: {
      helpText: {
        title: `Log id: ${id !== null && id}`,
        description: `Are you sure you want to cancel this ${text} ?`,
        type: 'null'
      }
    }
  };

  const getContentClassNames = color => {
    const contentClassNames = 'display-4  mb-2 font-weight-bold';
    return `${contentClassNames} text-${color}`;
  };

  return (
    <Fragment>
      <Modal isOpen={modalVisibale}>
        <LoadingOverlay active={loading} spinner={<Loader />} styles={overlay}>
          <ModalHeader toggle={e => handleClose(e)}>{`Cancel ${text}`}</ModalHeader>
          <ModalBody>
            <Card className="mb-3">
              <CardBody>
                {Object.entries(error).length > 0 &&
                  error.map(e => (
                    <div className="alert alert-danger" role="alert" key={newId()}>
                      {e}
                    </div>
                  ))}

                <div className={getContentClassNames('danger')} style={{ fontSize: '1rem' }}>
                  {`RFID TAG ${rfidtag !== null && rfidtag}`}
                </div>

                <hr />

                <Form
                  schema={schema}
                  uiSchema={uiSchema}
                  fields={fields}
                  onSubmit={handleSubmit}
                  formData={formdata}
                  autocomplete="off"
                >
                  <Fragment>
                    <button type="submit" className="btn btn-danger">
                      YES
                    </button>{' '}
                    <button className="btn btn-warning" onClick={e => handleClose(e)}>
                      NO
                    </button>
                  </Fragment>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </Fragment>
  );
};

export default Cancel;
