import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Input, Button, Form, FormGroup, Label } from 'reactstrap';

import Loader from '../../../../components/common/Loader';
import LoadingOverlay from 'react-loading-overlay';
import { signIn, markAsReadedLogsAdmin } from '../../../../services/ssg/ssg';

import newId from '../../../../helpers/newId';

const overlay = {
  overlay: base => ({
    ...base,
    background: 'rgb(236, 237, 237, 0.3)'
  })
};

function AdminSignInModal(props) {
  const token = useSelector(state => state.Ssg.token);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const modalVisibale = props.isOpen;

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState([]);

  useEffect(() => {
    setIsDisabled(!username || !password);
  }, [username, password]);

  useEffect(() => {
    seterror([]);
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    seterror([]);
    setloading(true);

    signIn({ username: username, password: password })
      .then(response => {
        readLogs(response.data.token);
      })
      .catch(err => {
        if (err.response !== undefined)
          try {
            err.response.data.errors.forEach(er => {
              seterror(prevArray => [...prevArray, er.message]);
            });
          } catch (ex) {
            seterror(['Please try again later...']);
          }
      });

    setloading(false);
  };

  const readLogs = async token => {
    setloading(true);

    const ids = { log_ids: props.logs.map(x => x.id) };
    console.log(ids);

    await markAsReadedLogsAdmin(ids, token)
      .then(response => {
        props.toggle();
        props.token(token);
        setloading(false);
        //window.location.reload();
      })
      .catch(err => {
        setloading(false);
        if (err.response !== undefined)
          try {
            err.response.data.errors.forEach(er => {
              seterror(prevArray => [...prevArray, er.message]);
            });
          } catch (ex) {
            seterror(['Please try again later...']);
          }
      });
  };

  return (
    <div>
      <Modal isOpen={modalVisibale} centered>
        <LoadingOverlay active={loading} spinner={<Loader />} styles={overlay}>
          <ModalHeader>Super Admin Login</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody className="fs--1">
                <Form onSubmit={handleSubmit}>
                  {Object.entries(error).length > 0 &&
                    error.map(e => (
                      <div className="alert alert-danger" role="alert" key={newId()}>
                        {e}
                      </div>
                    ))}
                  <FormGroup>
                    {<Label>User Name</Label>}
                    <Input
                      placeholder={'User Name'}
                      // value={username}
                      onChange={({ target }) => setUserName(target.value)}
                      type="string"
                    />
                  </FormGroup>
                  <FormGroup>
                    {<Label>Password</Label>}
                    <Input
                      placeholder={'Password'}
                      // value={password}
                      onChange={({ target }) => setPassword(target.value)}
                      type="password"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Button color="primary" block className="mt-3" disabled={isDisabled}>
                      Log in
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </div>
  );
}

export default AdminSignInModal;
