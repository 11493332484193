import API from '../../helpers/api';

//Auth **
export const signIn = authData => {
  return API.post('/auth/signin', authData);
};

export const forgotPassword = email => {
  return API.post('/auth/forgot-password', email);
};

export const resetPassword = resetDetails => {
  return API.post('/auth/reset-password', resetDetails);
};

export const getNewToken = refreshToken => {
  return API.post('/auth/token', refreshToken);
};

//Users **
export const getUsers = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('users', config);
};

export const getUsersCsvFormat = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/users/csv-format', config);
};

export const uploadUsersBulk = (data, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('users/bulk', data, config);
};

export const searchUsers = (q, group_id, status_id, page, limit, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      q: q,
      group_id: group_id,
      status_id: status_id,
      page: page,
      limit: limit
    }
  };

  return API.get('users/search', config);
};

export const exportUsers = (q, group_id, status_id) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      q: q,
      group_id: group_id,
      status_id: status_id,
      is_export: 1
    }
  };

  return API.get('users/search', config);
};

export const getUser = (userid, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('users/' + userid, config);
};

export const creatNewUser = (userInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('users', userInfor, config);
};

export const updateUser = (userid, userInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('users/' + userid, userInfor, config);
};

export const updateUserPassword = (userid, userInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('users/' + userid + '/password', userInfor, config);
};

export const deleteUser = (userid, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.delete('users/' + userid, config);
};

export const getUserLogs = (userId, page, size, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { page: page, limit: size }
  };

  return API.get('/users/' + userId + '/logs', config);
};

export const getUserCheckInOutLogs = (userId, page, size, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { page: page, limit: size }
  };

  return API.get('/users/' + userId + '/tracking-logs', config);
};

export const getUserSearch = (q, page, limit, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { q: q, page: page, limit: limit }
  };

  return API.get('/users/search', config);
};

//User profile **
export const getUserProfile = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('me', config);
};

export const updatePassword = (userInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('me/password', userInfor, config);
};

//Groups **
export const getUserGroups = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('groups', config);
};

export const getUserGroup = (groupid, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('groups/' + groupid, config);
};

export const createNewGroup = (groupInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('groups', groupInfor, config);
};

export const updateGroup = (groupId, groupInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('groups/' + groupId, groupInfor, config);
};

export const deleteGroup = (groupId, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.delete('groups/' + groupId, config);
};

//Roles **
export const getRoles = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('roles', config);
};

export const getUserRoles = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('me/roles', config);
};

//User Status **
export const getStatus = (type, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('status/' + type, config);
};

//User Ranks **
export const getRanks = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('ranks', config);
};

//Dvices **
export const getDevices = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/devices', config);
};

//Dashboard **
export const getDashboardData = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/dashboard/', config);
};

//Categories **
export const getCategories = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/categories', config);
};

export const getParentCategories = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/categories/parents', config);
};

export const getChildCategories = (parentCategoryId, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/categories/' + parentCategoryId + '/childs', config);
};

export const createNewCategory = (categoryInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('categories', categoryInfor, config);
};

export const updateCategory = (categoryId, categoryInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('categories/' + categoryId, categoryInfor, config);
};

export const deleteCategory = (categoryId, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.delete('categories/' + categoryId, config);
};

export const getAssetsFromCategoryId = (categoryId, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/categories/' + categoryId + '/assets', config);
};

//Assets **
export const getAssets = (page, size, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { page: page, limit: size }
  };

  return API.get('/assets', config);
};

export const getAssetLogs = (assetId, page, size, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { page: page, limit: size }
  };

  return API.get('/assets/' + assetId + '/logs', config);
};

export const getAssetAllLogs = (page, size, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { page: page, limit: size }
  };

  return API.get('/assets/logs', config);
};

export const getAsset = (assetId, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/assets/' + assetId, config);
};

export const getAssetByEpc = (epc, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/assets/by/epc/' + epc, config);
};

export const createNewtAsset = async (assetInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/assets', assetInfor, config);
};

export const createAssetCheckIn = async (id, assetInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/assets/' + id + '/return', assetInfor, config);
};

export const createAssetCheckOut = async (id, assetInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/assets/' + id + '/checkout', assetInfor, config);
};

export const createNewtAssetTage = async (assetId, tage, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/assets/' + assetId + '/tag', tage, config);
};

export const updateAsset = (assetId, assetInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('/assets/' + assetId, assetInfor, config);
};

export const deleteAsset = (assetId, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.delete('/assets/' + assetId, config);
};

export const updateAssetStatus = (assetId, assetInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('/assets/' + assetId + '/status', assetInfor, config);
};

export const getAssetStatusLogs = (assetId, page, size, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { page: page, limit: size }
  };

  return API.get('/assets/' + assetId + '/status', config);
};

//Assets Search
export const serchAssets = (q, status_id, category, page, limit, token, sort_by, sort) => {
  let category_id = [];
  category_id = [...category_id, category];

  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      q: q,
      status_id: status_id,
      category_id: category_id,
      page: page,
      limit: limit,
      sort_by: sort_by,
      sort: sort
    }
  };

  return API.get('/assets/search', config);
};

export const exportAssets = async (q, status_id, category, page, limit, token, sort_by, sort) => {
  let category_id = [];
  category_id = [...category_id, category];

  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      q: q,
      status_id: status_id,
      category_id: category_id,
      sort_by: sort_by,
      sort: sort,
      is_export: 1
    }
  };

  return API.get('/assets/search', config);
};

//Search logs daily basis
export const searchLogs = (data, page, limit, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      status: data === null ? null : data.status,
      from_date: data === null ? '' : data.from_date,
      to_date: data === null ? '' : data.to_date,
      authorized_user: data === null ? '' : data.authorized_user,
      user: data === null ? '' : data.user,
      asset: data === null ? '' : data.weapon,
      sort_by: data === null ? '' : data.sort_by,
      sort: data === null ? '' : data.sort,
      page: page,
      limit: limit
    }
  };

  return API.get('/assets/logs/daily-basis', config);
};

//Export logs daily basis
export const exportLogs = data => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      status: data === null ? null : data.status,
      from_date: data === null ? '' : data.from_date,
      to_date: data === null ? '' : data.to_date,
      authorized_user: data === null ? '' : data.authorized_user,
      user: data === null ? '' : data.user,
      asset: data === null ? '' : data.weapon,
      sort_by: data === null ? '' : data.sort_by,
      sort: data === null ? '' : data.sort,
      is_export: 1
    }
  };

  return API.get('/assets/logs/daily-basis', config);
};

//Zones **
export const getZones = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('zones', config);
};

//Readers **
export const getReadingLogs = (page, limit, sort_by, sort) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      page: page,
      limit: limit,
      sort_by: sort_by,
      sort: sort
    }
  };

  return API.get('/readers/logs', config);
};

export const getReadingLogsExport = (sort_by, sort) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      sort_by: sort_by,
      sort: sort,
      is_export: 1
    }
  };

  return API.get('/readers/logs', config);
};

export const getUnAuthLogs = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/readers/logs/unauth', config);
};

export const getAuthLogs = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/readers/logs/auth', config);
};

export const getUnEnrollLogs = token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/readers/logs/unenroll', config);
};

export const readerCheckOut = (id, info, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/readers/logs/' + id + '/checkout/', info, config);
};

export const readerCheckIn = (id, info, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/readers/logs/' + id + '/return', info, config);
};

export const markAsDuplicated = (id, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/readers/logs/' + id + '/duplicate/', {}, config);
};

export const enrollNewtAsset = async (id, assetInfor, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/readers/logs/' + id + '/enroll', assetInfor, config);
};

export const updateEnroll = async (id, asset, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.put('/readers/logs/' + id + '/enroll', asset, config);
};

export const markAsCancel = async (id, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/readers/logs/' + id + '/cancel', {}, config);
};

export const unReadLogs = async token => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.get('/readers/logs/unread', config);
};

export const markAsReadedLog = async (id, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/readers/logs/' + id + '/read', {}, config);
};

export const markAsReadedLogs = async (ids, token) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') }
  };

  return API.post('/readers/logs/read', ids, config);
};

export const markAsReadedLogsAdmin = async (ids, token) => {
  let config = {
    headers: { authorization: token }
  };

  return API.post('/readers/logs/read', ids, config);
};

// Inventory
export const getInventoryHistory = (page, size, sort_by, sort) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: { page: page, limit: size, sort_by: sort_by, sort: sort }
  };

  return API.get('/inventory/history', config);
};

export const getInventoryHistoryById = (id, status_id, is_downloadable, page, size, token, sort_by, sort) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      status_id: status_id,
      is_downloadable: is_downloadable,
      page: page,
      limit: size,
      sort_by: sort_by,
      sort: sort
    }
  };

  return API.get('/inventory/history/' + id, config);
};

//Reports

export const lostDamagedReport = (status_id, page, limit, sort_by, sort) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      status_id: status_id,
      page: page,
      limit: limit,
      sort_by: sort_by,
      sort: sort
    }
  };

  return API.get('/reports/' + status_id, config);
};

export const lostDamagedReportExport = (status_id, sort_by, sort) => {
  let config = {
    headers: { authorization: localStorage.getItem('armoryToken') },
    params: {
      status_id: status_id,
      is_export: 1,
      sort_by: sort_by,
      sort: sort
    }
  };

  return API.get('/reports/' + status_id, config);
};
