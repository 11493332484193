import React, { Fragment, createRef } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Row } from 'reactstrap';
import ButtonIcon from '../../../components/common/ButtonIcon';
import moment from 'moment';

const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
  <span>
    {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize} —{' '}
  </span>
);

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true
  },

  {
    dataField: 'user',
    text: 'USER',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    formatter: cell => {
      return cell !== null ? `${cell.first_name} ${cell.last_name}` : '';
    },
    sortValue: cell => {
      return cell !== null ? `${cell.first_name} ${cell.last_name}` : '';
    }
  },
  {
    dataField: 'asset.name',
    text: 'WEAPON',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'status.name',
    text: 'STATUS',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'asset.serial_number',
    text: '	SERIAL NR',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true
  },

  {
    dataField: 'rfid_tag',
    text: 'RFID TAG',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    formatter: cell => {
      if (cell !== null && cell !== undefined && cell !== '') {
        return `***${cell.substr(cell.length - 4)}`;
      } else {
        return cell;
      }
    }
  },

  {
    dataField: 'tracking_time',
    text: 'TRACKING TIME',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    formatter: time => {
      if (time !== null) {
        return `${moment(time).format('MM-DD-YYYY HH:mm:ss')}`;
      } else {
        return '';
      }
    }
  },
  {
    dataField: 'readUser',
    text: 'ACKNOWLEDGE USER',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    formatter: cell => {
      return cell !== null ? `${cell.first_name} ${cell.last_name}` : '';
    },
    sortValue: cell => {
      return cell !== null ? `${cell.first_name} ${cell.last_name}` : '';
    }
  },
  {
    dataField: 'read_time',
    text: 'ACKNOWLEDGE TIME',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    formatter: time => {
      if (time !== null) {
        return `${moment(time).format('MM-DD-YYYY HH:mm:ss')}`;
      } else {
        return '';
      }
    }
  }
];

const RecentAccesLogs = props => {
  let table = createRef();
  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const handleViewAll = ({ onSizePerPageChange }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, 1);
  };

  const options = {
    custom: true,
    sizePerPage: 10,
    totalSize: props.data.length
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = paginationProps.page * paginationProps.sizePerPage;

        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={props.data}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <CustomTotal {...paginationProps} lastIndex={lastIndex} />
                <ButtonIcon
                  color="link"
                  size="sm"
                  icon="chevron-right"
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  className="px-0 font-weight-semi-bold"
                  onClick={() => handleViewAll(paginationProps, props.data.length)}
                >
                  view all
                </ButtonIcon>
              </Col>
              <Col xs="auto" className="pr-3">
                <Button
                  color={paginationProps.page === 1 ? 'light' : 'primary'}
                  size="sm"
                  onClick={handlePrevPage(paginationProps)}
                  disabled={paginationProps.page === 1}
                  className="px-4"
                >
                  Previous
                </Button>
                <Button
                  color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                  size="sm"
                  onClick={handleNextPage(paginationProps)}
                  disabled={lastIndex >= paginationProps.totalSize}
                  className="px-4 ml-2"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

export default RecentAccesLogs;
