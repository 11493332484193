import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, ModalBody, ModalHeader, Modal } from 'reactstrap';
import { withTheme } from 'react-jsonschema-form';
import LayoutField from 'react-jsonschema-form-layout-2';
import Bootstrap4Theme from 'react-jsonschema-form-bs4';
import Loader from '../../../../../components/common/Loader';
import LoadingOverlay from 'react-loading-overlay';
import { getUsers, readerCheckIn } from '../../../../../services/ssg/ssg';
import newId from '../../../../../helpers/newId';
import uiSchema from './UiSchema';

const fields = {
  layout: LayoutField
};

const overlay = {
  overlay: base => ({
    ...base,
    background: 'rgb(236, 237, 237, 0.3)'
  })
};

const Form = withTheme(Bootstrap4Theme);

/**
 * Check-in popup window
 * @param {*} props
 * @returns
 */
const CheckIn = props => {
  const modalVisibale = props.isOpen;

  const token = useSelector(state => state.Ssg.token);

  const [error, seterror] = useState([]);
  const [loading, setloading] = useState(false);
  const [formdata, setformdata] = useState([]);

  const [enumusernames, setenumusernames] = useState([]);
  const [enumuserids, setenumuserids] = useState([]);

  /**
   * Get all the users
   * @param {*} id
   */
  useEffect(() => {
    getUsers(token)
      .then(response => {
        if (response.status === 200) {
          setenumusernames(response.data.map(m => `${m.first_name} ${m.last_name}`));
          setenumuserids(response.data.map(m => m.id));
        }
      })
      .catch(err => {
        try {
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          seterror(['Please try again later...']);
        }
      });
  }, []);

  /**
   * Call check-in API endpoint
   * @param {*} values
   */
  const handleSubmit = values => {
    seterror([]);
    setloading(true);
    setformdata(values.formData);

    readerCheckIn(props.formData.id, values.formData, token)
      .then(response => {
        if (response.status === 200) {
          setformdata([]);
          props.toggle();
        }
      })
      .catch(err => {
        try {
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          seterror(['Please try again later...']);
        }
      });

    setTimeout(function() {
      setloading(false);
    }, 1000);
  };

  /**
   * Handle close pop up window
   * @param {*} e
   */
  const handleClose = e => {
    setformdata([]);
    seterror([]);
    e.preventDefault();
    props.cancel();
  };

  /**
   * Schema for form
   */

  let schema = {
    type: 'object',
    required: ['user_id'],
    properties: {
      weapon: {
        title: `Weapon Name`,
        description: `${props.formData !== null && props.formData.name}`,
        type: 'null'
      },

      date_time: {
        title: `Date/Time`,
        description: `${props.formData !== null && props.formData.tracking_time}`,
        type: 'null'
      },

      serial_number: {
        title: `Serial Number`,
        description: `${props.formData !== null && props.formData.serial_number}`,
        type: 'null'
      },
      user_id: {
        title: 'Check OUT By',
        type: 'number',
        enum: enumuserids,
        enumNames: enumusernames
      }
    }
  };

  const getContentClassNames = color => {
    const contentClassNames = 'display-4  mb-2 font-weight-bold';
    return `${contentClassNames} text-${color}`;
  };

  return (
    <Fragment>
      <Modal isOpen={modalVisibale}>
        <LoadingOverlay active={loading} spinner={<Loader />} styles={overlay}>
          <ModalHeader toggle={e => handleClose(e)}>Check IN</ModalHeader>
          <ModalBody>
            <Card className="mb-3">
              <CardBody>
                {Object.entries(error).length > 0 &&
                  error.map(e => (
                    <div className="alert alert-danger" role="alert" key={newId()}>
                      {e}
                    </div>
                  ))}

                <div className={getContentClassNames('success')} style={{ fontSize: '1rem' }}>
                  {`RFID TAG ${props.formData !== null && props.formData.rfid_tag}`}
                </div>

                <hr />

                <Form
                  schema={schema}
                  uiSchema={uiSchema}
                  fields={fields}
                  onSubmit={handleSubmit}
                  formData={formdata}
                  autocomplete="off"
                >
                  <Fragment>
                    <button type="submit" className="btn btn-success">
                      Check IN
                    </button>{' '}
                    <button className="btn btn-warning" onClick={e => handleClose(e)}>
                      Cancel
                    </button>
                  </Fragment>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </Fragment>
  );
};

export default CheckIn;
