export const homeRoutes = {
  name: 'Dashboard',
  to: '/dashboard',
  exact: true,
  icon: 'chart-pie'
};

export const usersRoutes = {
  name: 'Users',
  to: '/users',
  icon: 'users',
  children: [{ to: '/users/groups', name: 'Groups' }, { to: '/users/users', name: 'Users' }]
};

export const armoryMangementRoutes = {
  name: 'Armory Management',
  to: '/armory',
  icon: 'puzzle-piece',
  children: [
    { to: '/armory/categories', name: 'Categories' },
    { to: '/armory/weapons', name: 'Weapons' },
    // { to: '/armory/zones', name: 'Zones' },
    { to: '/armory/devices', name: 'Devices' }
  ]
};

export const readersRoutes = {
  name: `Unauthorized Access Logs`,
  to: '/readers',
  icon: 'book'
};

export const reportRoutes = {
  name: `Reports`,
  to: '/reports',
  icon: 'stream',
  children: [
    { to: '/reports/access', name: 'Access' },
    { to: '/reports/lost', name: 'Lost' },
    { to: '/reports/damaged', name: 'Damaged' }
  ]
};

export const inventoryRoutes = {
  name: `Inventory`,
  to: '/inventory',
  icon: 'pencil-alt'
};

// export const enrolWeaponsRoutes = {
//   name: `Enroll Weapons`,
//   to: '/enroll',
//   icon: 'pencil-alt'
// };

// export const reportsRoutes = {
//   name: 'Reports',
//   to: '/ssg/reports',
//   icon: 'stream',
//   children: [{ to: '/ssg/planbasedreports/', name: 'Plan Based Report' }]
// };

export default [homeRoutes, usersRoutes, armoryMangementRoutes, readersRoutes, reportRoutes, inventoryRoutes];
