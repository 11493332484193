import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Media, Row } from 'reactstrap';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import Loader from '../../../components/common/Loader';
import moment from 'moment';

const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

const getContentClassNames = color => {
  const contentClassNames = 'display-4 fs-4 mb-2 font-weight-normal text-sans-serif';
  if (color === 'success') return contentClassNames;
  return `${contentClassNames} text-${color}`;
};

const CardSummary = ({ title, color, dashboardloading, transactions }) => {
  return (
    <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
      <Background image={getImage(color)} className="bg-card" />

      <CardBody className="position-relative">
        <Fragment>
          <h6>{title}</h6>

          <div>
            <Row>
              <Col md={7}>
                <div>Check-OUTs</div>
              </Col>
              <Col>
                <div className={getContentClassNames(color)}>{transactions.checkouts}</div>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col md={7}>
                <div>Check-INs</div>
              </Col>
              <Col>
                <div className={getContentClassNames(color)}>{transactions.returns}</div>
              </Col>
            </Row>
          </div>
        </Fragment>
      </CardBody>
    </Card>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.object
};

CardSummary.defaultProps = {
  linkText: 'See all',
  to: '#!',
  color: 'primary'
};

export default CardSummary;
