import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import SsgError404 from '../containers/ssg/errors/Error404';
import SSgError500 from '../containers/ssg/errors/Error500';
import Logo from '../components/navbar/Logo';
import Section from '../components/common/Section';

const ErrorLayout = ({ match: { url } }) => {
  const ssgUserType = useSelector(state => state.Ssg.userType);
  // const customerUserType = useSelector(state => state.Customer.userType);

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo />

          {ssgUserType && (
            <Switch>
              <Route path={`${url}/404`} component={SsgError404} />
              <Route path={`${url}/500`} component={SSgError500} />

              {/*Redirect*/}
              <Redirect to={`${url}/404`} />
            </Switch>
          )}
        </Col>
      </Row>
    </Section>
  );
};

ErrorLayout.propTypes = { match: PropTypes.object };

export default ErrorLayout;
