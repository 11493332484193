import { SSG_AUTH_SUCCESS } from '../../actions/ssg/types/types';

export const checkAuthorization = () => {
  return dispatch => {
    const token = localStorage.getItem('armoryToken');
    const userId = localStorage.getItem('armoryUserId');
    const userType = localStorage.getItem('armoryUserType');
    const avatar = localStorage.getItem('armoryAvatar');

    if (userType && userId && token) {
      if (userType === 'armory') {
        dispatch({
          type: SSG_AUTH_SUCCESS,
          payload: { token, userId, userType, avatar }
        });
      }
    }
  };
};
