import { SSG_AUTH_START, SSG_AUTH_FAIL, SSG_AUTH_SUCCESS, SSG_AUTH_OUT } from '../types/types';
import { signIn } from '../../../services/ssg/ssg';

export const authStart = () => {
  return {
    type: SSG_AUTH_START,
    payload: { error: null }
  };
};

export const authSuccess = (token, refreshToken, userId, userType, avatar) => {
  return {
    type: SSG_AUTH_SUCCESS,
    payload: { token, refreshToken, userId, userType, avatar }
  };
};

export const authFail = error => {
  return {
    type: SSG_AUTH_FAIL,
    payload: { error }
  };
};

export const logout = () => {
  // await signOutService();

  localStorage.removeItem('armoryToken');
  localStorage.removeItem('armoryRefreshToken');
  localStorage.removeItem('armoryUserId');
  localStorage.removeItem('armoryUserType');

  return {
    type: SSG_AUTH_OUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (username, password) => {
  return dispatch => {
    dispatch(authStart());

    const authData = {
      username: username,
      password: password
    };

    signIn(authData)
      .then(response => {
        localStorage.setItem('armoryToken', response.data.token);
        localStorage.setItem('armoryRefreshToken', response.data.refresh_token);
        localStorage.setItem('armoryUserId', response.data.user.id);
        localStorage.setItem('armoryUserType', 'armory');
        // localStorage.setItem('avatar',response.data.data.doctor.avatar);

        dispatch(authSuccess(response.data.token, response.data.refresh_token, response.data.user.id, 'armory', null));
      })
      .catch(err => {
        try {
          dispatch(authFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(authFail('Please try again later..'));
        }
      });
  };
};

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('armoryToken');
    const userId = localStorage.getItem('armoryRefreshToken');
    if (!token) {
      dispatch(logout());
    } else if (!userId) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token, userId));
    }
  };
};
