export default {
  //Develop
  apiUrl: 'http://67.23.72.77:8084/api/',

  // apiUrl: 'https://soteria-api.cenango.com/api/',

  //production
  // apiUrl: 'http://127.0.0.1:8082/api/',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1'
  }
};
