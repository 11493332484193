import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Media, Row } from 'reactstrap';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import moment from 'moment';
import Loader from '../../../components/common/Loader';

const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

const getContentClassNames = color => {
  const contentClassNames = 'display-4 fs-4 mb-2 font-weight-normal text-sans-serif';
  if (color === 'success') return contentClassNames;
  return `${contentClassNames} text-${color}`;
};

// const CardSummary = ({ title, color, dashboardloading, totalassets, users, categories }) => {
//   return (
//     <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
//       <Background image={getImage(color)} className="bg-card" />

//       <CardBody className="position-relative">
//         {dashboardloading === true ? (
//           <Loader />
//         ) : (
//           <Fragment>
//             <h6>{title}</h6>
//             <div>
//               <div style={{ display: 'inline-block', marginRight: '20px' }}>Weapons</div>

//               <div className={getContentClassNames(color)} style={{ display: 'inline-block' }}>
//                 {totalassets}
//               </div>
//             </div>

//             <div style={{ marginTop: '-5px' }}>
//               <div style={{ display: 'inline-block', marginRight: '45px' }}>Users</div>

//               <div className={getContentClassNames(color)} style={{ display: 'inline-block' }}>
//                 {users}
//               </div>
//             </div>

//             <div style={{ marginTop: '-5px' }}>
//               <div style={{ display: 'inline-block', marginRight: '10px' }}>Categories</div>

//               <div className={getContentClassNames(color)} style={{ display: 'inline-block' }}>
//                 {categories}
//               </div>
//             </div>
//           </Fragment>
//         )}
//       </CardBody>
//     </Card>
//   );
// };

const CardSummary = ({ title, color, inventory, dashboardloading }) => {
  return (
    <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
      <Background image={getImage(color)} className="bg-card" />
      <CardBody className="position-relative">
        <Fragment>
          <h6>{`${
            inventory === null || inventory.inventory_time === undefined
              ? 'Inventory Not Available at This Time'
              : `${title} at ${moment(inventory.inventory_time).format('MM-DD-YYYY HH:mm:ss')}`
          }`}</h6>

          <div>
            <Row>
              <Col md={7}>
                <div>Check-Outs</div>
              </Col>
              <Col>
                <div className={getContentClassNames(color)}>{inventory === null ? 0 : inventory.checkouts}</div>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col md={7}>
                <div>In Location</div>
              </Col>
              <Col>
                <div className={getContentClassNames(color)}>{inventory === null ? 0 : inventory.in_location}</div>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col md={7}>
                <div>Not in Location</div>
              </Col>
              <Col>
                <div className={getContentClassNames(color)}>{inventory === null ? 0 : inventory.not_in_location}</div>
              </Col>
            </Row>
          </div>
        </Fragment>
      </CardBody>
    </Card>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.object
};

CardSummary.defaultProps = {
  linkText: 'See all',
  to: '#!',
  color: 'primary'
};

export default CardSummary;
