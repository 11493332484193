import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Card, CardBody } from 'reactstrap';
import { withTheme } from 'react-jsonschema-form';
import LayoutField from 'react-jsonschema-form-layout-2';
import Bootstrap4Theme from 'react-jsonschema-form-bs4';
import Loader from '../../../../components/common/Loader';
import LoadingOverlay from 'react-loading-overlay';
import { signIn } from '../../../../services/ssg/ssg';
import uiSchema from './UiSchema';
import newId from '../../../../helpers/newId';
import { widgets } from '../../../../helpers/utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import errorImage from '../../../../assets/img/icons/error.png';
import { ImageOverlay } from 'leaflet';
import { markAsReadedLogs } from '../../../../services/ssg/ssg';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const overlay = {
  overlay: base => ({
    ...base,
    background: 'rgb(236, 237, 237, 0.3)'
  })
};

function AleartModal(props) {
  const history = useHistory();
  const token = useSelector(state => state.Ssg.token);

  const modalVisibale = props.isOpen;
  // const formdata = props.formData;

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState([]);

  const [formdata, setformdata] = useState(null);

  const Form = withTheme(Bootstrap4Theme);

  const fields = {
    layout: LayoutField
  };

  const handleClose = () => {
    seterror([]);
    // setformdata(null);
    props.cancel();
  };

  const handleRead = async () => {
    // props.handleRead();
    setloading(true);
    history.push('/readers');

    const ids = { log_ids: props.logs.map(x => x.id) };

    await markAsReadedLogs(ids, token)
      .then(response => {
        // props.toggle();
        props.token(token);
        setloading(false);
        // window.location.reload();
      })
      .catch(err => {
        console.log(err.response);
        setloading(false);
        if (err.response !== undefined)
          try {
            err.response.data.errors.forEach(er => {
              props.setErrorText('Admin Acknowledgement Required');
              props.setvisibaleBtn(true);
            });
          } catch (ex) {
            seterror(['Please try again later...']);
          }
      });
  };

  const handleAcknowledge = () => {
    props.handleAcknowledge();
  };

  return (
    <div>
      <Modal isOpen={modalVisibale} centered size="lg">
        <LoadingOverlay active={loading} spinner={<Loader />} styles={overlay}>
          <ModalBody style={{ backgroundColor: 'red' }}>
            <Card className="text-center">
              <CardBody className="p-5">
                {Object.entries(error).length > 0 &&
                  error.map(e => (
                    <div className="alert alert-danger" role="alert" key={newId()}>
                      {e}
                    </div>
                  ))}

                <div>
                  <img src={errorImage} width="100px" height="100px" />
                </div>
                <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
                  Unauthorized Access Detected !!!
                </p>
                <hr />

                {props.text !== [] &&
                  props.text.map(d => (
                    <div>{` ${d.asset.name} [Serial Nr.: ${d.asset.serial_number}]
           Time: ${moment(d.tracking_time).format('MM-DD-yyyy h:mm:ss a')}`}</div>
                  ))}

                <div style={{ fontStyle: 'italic' }}>{props.errorText}</div>
                <div style={{ marginTop: '10px' }}>
                  <button
                    className="btn btn-primary"
                    onClick={e => handleRead(e)}
                    style={{ visibility: props.visibaleAcknowledgeBtn === true ? 'hidden' : 'visible' }}
                  >
                    OK
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={e => handleAcknowledge(e)}
                    style={{ visibility: props.visibaleAcknowledgeBtn === true ? 'visible' : 'hidden' }}
                  >
                    ACKNOWLEDGE
                  </button>
                </div>
              </CardBody>
            </Card>
          </ModalBody>{' '}
        </LoadingOverlay>
      </Modal>
    </div>
  );
}

export default AleartModal;
