import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAssetAllLogs, getUnAuthLogs, getAuthLogs, getDashboardData } from '../../../services/ssg/ssg';
import Loader from '../../../components/common/Loader';
import { Col, Row, Card, CardBody, Alert } from 'reactstrap';
import newId from '../../../helpers/newId';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import CardSummary from './CardSummary';
import CardSummaryInventory from './CardSummaryInventory';
import CardSummaryCheckOuts from './CardSummaryICheckOuts';
import CardSummaryITransactions from './CardSummaryITransactions';
import CardSummaryIWeapons from './CardSummaryIWeapons';
import RecentAccessLogs from './RecentAccesLogs';
import { isIterableArray } from '../../../helpers/utils';
import Logs from './Logs';

const DashboardAlt = props => {
  let history = useHistory();
  const token = useSelector(state => state.Ssg.token);

  // State
  const [loading, setloading] = useState(false);
  const [recentloading, setrecentloading] = useState(false);
  const [dashboardloading, setdashboardloading] = useState(false);

  const [error, seterror] = useState([]);

  const [logs, setlogs] = useState([]);
  const [recentlogs, setrecentlogs] = useState([]);
  const [reload, setreload] = useState(false);
  const [todaycheckouts, settodaycheckouts] = useState(null);
  const [totalcheckouts, settotalcheckouts] = useState(null);
  const [totalassets, settotalassets] = useState(null);
  const [inventory, setinventory] = useState(null);
  const [time, setTime] = useState(Date.now());
  const [checkouts, setcheckouts] = useState({});
  const [transactions, settransactions] = useState({});
  const [users, setusers] = useState(null);
  const [categories, setcategories] = useState(null);
  const [totalweapons, settotalweapons] = useState([]);

  // useEffect(() => {
  //   getLogs();
  // }, [reload]);

  useEffect(() => {
    let rotationInterval = setInterval(() => {
      getDashboard();
      getAutLogs();
    }, 1000 * 60);

    //Clean up can be done like this...
    return () => {
      clearInterval(rotationInterval);
    };
  }, [time]);

  useEffect(() => {
    getAutLogs();
    getDashboard();
  }, []);

  // const getLogs = () => {
  //   seterror([]);
  //   setloading(true);

  //   getUnAuthLogs(token)
  //     .then(response => {
  //       setlogs(response.data.records);
  //     })
  //     .catch(err => {
  //       try {
  //         setloading(false);
  //         err.response.data.errors.forEach(er => {
  //           seterror(prevArray => [...prevArray, er.message]);
  //         });
  //       } catch (ex) {
  //         setloading(false);
  //         seterror(['Please try again later...']);
  //       }
  //     });

  //   setTimeout(function() {
  //     setloading(false);
  //   }, 1000);
  // };

  const getAutLogs = () => {
    seterror([]);
    setrecentloading(true);

    getAssetAllLogs(1, 20, token)
      .then(response => {
        setrecentlogs(response.data.records);
      })
      .catch(err => {
        try {
          setloading(false);
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          setloading(false);
          seterror(['Please try again later...']);
        }
      });

    setTimeout(function() {
      setrecentloading(false);
    }, 1000);
  };

  const getDashboard = () => {
    seterror([]);
    setdashboardloading(true);

    getDashboardData(token)
      .then(response => {
        // console.log(response.data);
        settodaycheckouts(response.data.today_checkouts);
        settotalcheckouts(response.data.total_checkouts);
        settotalassets(response.data.total_assets);
        setinventory(response.data.last_inventory_log);
        setcheckouts({ total: response.data.total_checkouts, today: response.data.today_checkouts });
        settransactions(response.data.today_transactions);
        setusers(response.data.total_users);
        setcategories(response.data.total_categories);
        settotalweapons(response.data.weapon_counts);
      })
      .catch(err => {
        try {
          setloading(false);
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          setloading(false);
          seterror(['Please try again later...']);
        }
      });

    setTimeout(function() {
      setdashboardloading(false);
    }, 1000);
  };

  useEffect(() => {
    if (token === null) {
      history.push('/');
    }
  }, []);

  // const refreshPage = () => {
  //   getLogs();
  // };

  const reLoad = () => {
    setreload(!reload);
  };

  return (
    <Fragment>
      {Object.entries(error).length > 0 &&
        error.map(e => (
          <div className="alert alert-danger" role="alert" key={newId()}>
            {e}
          </div>
        ))}

      <Fragment>
        {/*       
        <Row style={{ marginTop: '25px' }}>
          <Col md={3}>
            <Card className="mb-3">
              <ButtonIcon size="xl" icon="redo" color="primary" onClick={() => refreshPage()}>
                <span style={{ fontWeight: 'bold' }}>CHECK IN / CHECK OUT</span>
              </ButtonIcon>
            </Card>
          </Col>

          <Col md={3}>
            <Card className="mb-3">
              <ButtonIcon size="xl" icon="pencil-alt" color="warning" onClick={() => props.history.push('/enroll')}>
                <span style={{ fontWeight: 'bold' }}>ENROLL WEAPONS</span>
              </ButtonIcon>
            </Card>
          </Col>
        </Row> */}

        {/* <Row>
          <Col md={12}>
            <Card className="mb-3">
              <CardBody className="p-0">
                {loading ? (
                  <Loader />
                ) : isIterableArray(logs) ? (
                  <Logs data={logs} reLoad={() => reLoad()} />
                ) : (
                  <Row className="p-card">
                    <Col>
                      <Alert color="info" className="mb-0">
                        No logs found!
                      </Alert>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        {/* <div className="card-deck">
          <CardSummaryCheckOuts
            title="CHECK-OUTs"
            color="info"
            dashboardloading={dashboardloading}
            checkouts={checkouts}
          /> */}

        <div className="card-deck">
          <CardSummaryCheckOuts
            title="Weapon Counts"
            color="info"
            dashboardloading={dashboardloading}
            weapons={totalweapons}
          />

          <CardSummaryITransactions
            title="Today Transactions"
            color="warning"
            dashboardloading={dashboardloading}
            transactions={transactions}
          />

          {/* <CardSummary title="Total CHECK - OUTs" color="warning">
            {dashboardloading ? <Loader /> : totalcheckouts === null ? 0 : totalcheckouts}
          </CardSummary> */}

          {/* <CardSummary title="Total Weapons" color="success">
            {dashboardloading ? <Loader /> : totalassets === null ? 0 : totalassets}
          </CardSummary> */}

          <CardSummaryIWeapons
            title="Stats"
            color="info"
            dashboardloading={dashboardloading}
            totalassets={totalassets}
            users={users}
            categories={categories}
          />

          <CardSummaryInventory
            title="Last Inventory"
            color="warning"
            inventory={inventory}
            dashboardloading={dashboardloading}
          />
        </div>

        <Row>
          <Col md={12}>
            <Card className="mb-3">
              <FalconCardHeader title="Recently Access Logs" light={false} />
              <CardBody className="p-0">
                {/* {recentloading ? (
                  <Loader />
                ) :  */}

                {isIterableArray(recentlogs) ? (
                  <RecentAccessLogs data={recentlogs} />
                ) : (
                  <Row className="p-card">
                    <Col>
                      <Alert color="info" className="mb-0">
                        No Recent logs found!
                      </Alert>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row noGutters />
      </Fragment>
    </Fragment>
  );
};

export default DashboardAlt;
