import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import newId from '../../../helpers/newId';
import { forgotPassword } from '../../../services/ssg/ssg';
import * as actions from '../../../actions/ssg/index';

const ForgetPasswordForm = ({ setRedirect, setRedirectUrl, layout }) => {
  const dispatch = useDispatch();

  // State
  const [email, setEmail] = useState('');
  const [error, seterror] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      forgotPassword({ email: email })
        .then(response => {
          if (response.status === 200) {
            dispatch(actions.confirmMail(email));
            toast.success(`An email is sent to ${email} with password reset link`);
            setRedirect(true);
          }
        })
        .catch(err => {
          try {
            err.response.data.errors.forEach(er => {
              seterror(prevArray => [...prevArray, { id: newId(), message: er.message }]);
            });
          } catch (ex) {
            seterror(['Please try again later...']);
          }
        });
    }
  };

  useEffect(() => {
    setRedirectUrl(`/admin/confirm-mail/`);
  }, [setRedirectUrl, layout]);

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <FormGroup>
        {Object.entries(error).length > 0 &&
          error.map(e => (
            <div className="alert alert-danger" role="alert" key={e.id}>
              {e.message}
            </div>
          ))}
        <Input
          className="form-control"
          placeholder="Email address"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        <Button color="primary" block disabled={!email}>
          Send reset link
        </Button>
      </FormGroup>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string
  // email: PropTypes.email
};

ForgetPasswordForm.defaultProps = { layout: 'basic' };

export default withRedirect(ForgetPasswordForm);
