import { SSG_STATUS_START, SSG_STATUS_FAIL, SSG_STATUS_SUCCESS } from '../types/types';
import * as actions from '../../../services/ssg/ssg';

export const statusStart = () => {
  return {
    type: SSG_STATUS_START,
    payload: { error: null }
  };
};

export const statusSuccess = status => {
  return {
    type: SSG_STATUS_SUCCESS,
    payload: status
  };
};

export const statusFail = error => {
  return {
    type: SSG_STATUS_FAIL,
    payload: { error }
  };
};

export const status = (type, token) => {
  return dispatch => {
    dispatch(statusStart());

    actions
      .getStatus(type, token)
      .then(response => {
        dispatch(statusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(statusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(statusFail('Please try again later..'));
        }
      });
  };
};
