import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Navbar, Nav } from 'reactstrap';
import AppContext from '../../../context/Context';
import Logo from './Logo';
import ProfileDropdown from './ProfileDropdown';
import ReactPlayer from 'react-player';

import moment from 'moment';
import { unReadLogs, markAsReadedLog, getUserRoles } from '../../../services/ssg/ssg';
import alarm from '../../../assets/audio/mixkit-alert-alarm-1005.wav';
import AdminSignInModal from './adminsignin/AdminSignInModal';
import AleartModal from './alert/AleartModal';
import { useHistory } from 'react-router-dom';

const NavbarTop = () => {
  const history = useHistory();
  const token = useSelector(state => state.Ssg.token);
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);
  const [time, setTime] = useState(Date.now());
  const [logs, setlogs] = useState([]);
  const [isPlaying, setIsplaying] = useState(false);
  const [signinmodalvisibale, setsigninmodalvisibale] = useState(false);
  const [alertvisibale, setalertvisibale] = useState(false);
  const [visibaleAcknowledgeBtn, setvisibaleAcknowledgeBtn] = useState(false);
  const [errortext, seterrortext] = useState('');
  const [intervalid, setIntervalid] = useState();
  const [text, settext] = useState([]);

  useEffect(() => {
    startTimer();
  }, [0]);

  const startTimer = () => {
    const rotationInterval = setInterval(() => {
      setIntervalid(rotationInterval);

      //Get all the unread logs
      unReadLogs(token)
        .then(response => {
          // let text = '';

          setlogs(response.data);
          console.log(response.data);

          // response.data.forEach(d => {
          //   text += ` ${d.asset.name} [Serial Nr.: ${d.asset.serial_number}]
          //  Time: ${moment(d.tracking_time).format('MM-DD-yyyy h:mm:ss a')}, `;
          // });

          if (response.data.length > 0) {
            //start the alarm
            setIsplaying(true);

            settext(response.data);

            setalertvisibale(true);
          } else {
            setalertvisibale(false);
            setsigninmodalvisibale(false);
            seterrortext('');
            setvisibaleAcknowledgeBtn(false);
            // stop alarm
            setIsplaying(false);

            //FOR DEMO version
            // if (window.location.pathname === '/readers') {
            //   window.location.reload();
            // }
          }
        })
        .catch(err => {});
    }, 1000 * 5);

    //Clean up can be done like this...
    return () => {
      clearInterval(rotationInterval);
    };
  };

  const handleSigninModalClose = () => {
    setsigninmodalvisibale(false);
    setalertvisibale(false);
  };

  // const handleRead = () => {
  //   history.push('/readers');

  //   logs.forEach(async d => {
  //     await markAsReadedLog(d.id, token)
  //       .then(response => {
  //         setalertvisibale(false);
  //         setIsplaying(false);
  //         window.location.reload();
  //       })
  //       .catch(err => {
  //         console.log(err.response);
  //         if (err.response.status === 403) {
  //           seterrortext('Admin Acknowledgement Required');
  //           setvisibaleAcknowledgeBtn(true);
  //         }
  //       });
  //   });
  // };

  const handleAcknowledge = () => {
    setalertvisibale(true);
    setsigninmodalvisibale(true);
  };

  const updateReadLog = tkn => {
    setIsplaying(false);
    setalertvisibale(false);
  };

  return (
    <Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand>
      <div className="toggle-icon-wrapper d-xl-none">
        <button
          className="navbar-vertical-toggle btn btn-link d-flex align-item-center justify-content-center "
          onClick={() => {
            setShowBurgerMenu(!showBurgerMenu);
          }}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
        {/* <ReactPlayer url={alarm} controls={true} height={'20px'} loop={true} playing={isPlaying} /> */}
      </div>
      <Logo at="navbar-top" width={40} id="topLogo" />
      <Collapse navbar>
        <Nav navbar className="align-items-center d-none d-lg-block" />

        <Nav navbar className="align-items-center ml-auto">
          {/* <NotificationDropdown /> */}
          <ProfileDropdown />
        </Nav>
      </Collapse>

      <AdminSignInModal
        isOpen={signinmodalvisibale}
        toggle={handleSigninModalClose}
        token={e => updateReadLog(e)}
        logs={logs}
      />

      <AleartModal
        isOpen={alertvisibale}
        // handleRead={handleRead}
        token={e => updateReadLog(e)}
        visibaleAcknowledgeBtn={visibaleAcknowledgeBtn}
        handleAcknowledge={handleAcknowledge}
        errorText={errortext}
        text={text}
        logs={logs}
        setErrorText={e => seterrortext(e)}
        setvisibaleBtn={e => setvisibaleAcknowledgeBtn(e)}
      />
    </Navbar>
  );
};

export default NavbarTop;
