import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';

import SsgLogin from '../containers/ssg/auth/split/login';
import SsgLogout from '../containers/ssg/auth/split/Logout';
import SsgForgetPassword from '../containers/ssg/auth/split/ForgetPassword';
import SsgPasswordReset from '../containers/ssg/auth/split/PasswordReset';
import SsgConfirmMail from '../containers/ssg/auth/split/ConfirmMail';

import ErrorLayout from './ErrorLayout';

const Layout = props => {
  const ssgUserType = useSelector(state => state.Ssg.userType);

  return (
    <Router fallback={<span />}>
      <Switch>
        {/*SSG login*/}
        <Route path="/" exact component={SsgLogin} />
        <Route path="/logout" exact component={SsgLogout} />
        <Route path="/admin/forget-password" exact component={SsgForgetPassword} />
        <Route path="/admin/password-reset/:token" exact component={SsgPasswordReset} />
        <Route path="/admin/confirm-mail/" exact component={SsgConfirmMail} />
        <Route path="/errors" component={ErrorLayout} />
        {ssgUserType === 'armory' && <Route component={DashboardLayout} />}
        <Redirect to="/" />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default withRouter(Layout);
