import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import * as actions from '../../../actions/ssg/index';

const LoginForm = ({ setRedirect, hasLabel, layout }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();

  const authError = useSelector(state => state.Ssg.authError);
  const isLoggedIn = useSelector(state => state.Ssg.token);
  const loggedUserType = useSelector(state => state.Ssg.userType);
  // const authLoading = useSelector(state => state.Ssg.authLoading);

  // State
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    dispatch(actions.auth(username, password));

    if (authError === null && isLoggedIn) {
      history.push('/dashboard');
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setRedirectToDashboard(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setIsDisabled(!username || !password);
  }, [username, password]);

  let from_pathname = '/';
  if (loggedUserType === 'armory') {
    from_pathname = '/dashboard';
  }

  let { from } = location.state || { from: { pathname: from_pathname } };

  if (redirectToDashboard) {
    return <Redirect to={from} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      {authError && (
        <div className="alert alert-danger" role="alert">
          {authError}
        </div>
      )}
      <FormGroup>
        {hasLabel && <Label>User Name</Label>}
        <Input
          placeholder={!hasLabel ? 'User Name' : ''}
          value={username}
          onChange={({ target }) => setUserName(target.value)}
          type="string"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        {/* <Col xs="auto">
          <Link className="fs--1" to={`/admin/forget-password`}>
            Forget Password?
          </Link>
        </Col> */}
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(LoginForm);
